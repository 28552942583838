<template>
  <div class="booking-status-label">
    <v-chip
      v-if="booking.status === 'event'"
      color="purple"
      dark
      label
      :small="small"
      :large="large"
    >
      BP Event
    </v-chip>
    <v-chip
      v-else-if="booking.status === 'confirmed'"
      color="success"
      label
      :small="small"
      :large="large"
    >
      Bestätigt
    </v-chip>
    <v-chip
      v-else-if="booking.status === 'noshow'"
      color="info"
      label
      :small="small"
      :large="large"
    >
      No Show
    </v-chip>
    <v-chip
      v-else-if="booking.status === 'canceled'"
      color="error"
      label
      :small="small"
      :large="large"
    >
      Storniert
    </v-chip>
    <v-chip
      v-else-if="booking.status === 'blocker'"
      color="info"
      label
      :small="small"
      :large="large"
    >
      Blocker
    </v-chip>
    <v-chip v-else color="warning" label :small="small" :large="large">
      In Bearbeitung
    </v-chip>
  </div>
</template>

<script>
export default {
  props: {
    booking: {
      type: Object,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss"></style>
