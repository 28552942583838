<template>
  <div class="users-edit mt-4 ml-8 mr-8">
    <v-container fluid v-if="booking">
      <v-row>
        <v-col>
          <h1 class="display-1 mt-3">Buchung #{{ booking.refid }}</h1>
        </v-col>
        <v-col cols="auto">
          <booking-status-label :booking="booking" class="mt-3" />
        </v-col>
        <v-col cols="auto">
          <tooltip-button
            icon="history"
            text="History"
            color="default"
            :dark="false"
            @click="$refs.entityLogDialog.open = true"
            classNames="mt-1 mr-3"
            position="top"
            size="small"
          />
          <template v-if="booking.status !== 'event'">
            <tooltip-button
              icon="print"
              text="Function Sheet"
              color="default"
              :dark="false"
              @click="exportFunctionSheet()"
              classNames="mr-3"
              position="top"
            />
            <tooltip-button
              icon="receipt"
              text="Beleg"
              color="default"
              :dark="false"
              @click="exportReceipt()"
              position="top"
            />
          </template>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-card color="default" outlined class="elevation-0 mb-6">
            <v-card-title class="card-title-grey">
              Details
            </v-card-title>
            <v-card-text>
              <p>
                <b>Erstellt am:</b> {{ booking.created_at | formatDateTime }}
                <br />
                <b>Erstellt von: </b>
                <template v-if="$permissions.hasPermission('users')">
                  <router-link :to="`/users/${booking.creator.id}`">
                    {{ booking.creator.first_name }}
                    {{ booking.creator.last_name }}
                  </router-link>
                </template>
                <template v-else>
                  {{ booking.creator.first_name }}
                  {{ booking.creator.last_name }}
                </template>
              </p>
              <v-row>
                <v-col>
                  <h3 class="black--text">
                    Titel
                  </h3>
                  {{ booking.subject ? booking.subject : "-" }}
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h3 class="black--text">
                    Raum
                  </h3>
                  {{ booking.room.name }}
                </v-col>
                <v-col>
                  <h3 class="black--text">
                    Bestuhlung
                  </h3>
                  {{ seating }}
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h3 class="black--text">
                    Datum
                  </h3>
                  {{ bookingDate }}
                </v-col>
                <v-col>
                  <h3 class="black--text">
                    Uhrzeit
                  </h3>
                  {{ bookingStart }} - {{ bookingEnd }}
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h3 class="black--text">
                    Referenz
                  </h3>
                  {{ booking.reference ? booking.reference : "Keine Angabe" }}
                </v-col>
                <v-col>
                  <h3 class="black--text">
                    Kostenstelle
                  </h3>
                  <div v-if="booking.is_cost_center">
                    {{
                      booking.creator.cost_center
                        ? booking.creator.cost_center
                        : "Ja, aber unbekannt"
                    }}
                  </div>
                  <div v-else>Keine Angabe</div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card color="default" outlined class="elevation-0 mb-6">
            <v-card-title class="card-title-grey">
              Add-Ons
            </v-card-title>
            <v-card-text>
              <div v-if="booking.addon_items.length > 0">
                <v-row
                  v-for="(addonItem, index) in booking.addon_items"
                  :key="index"
                >
                  <v-col>
                    <h4 class="black--text">
                      <b>
                        {{ addonItem.name }}
                      </b>
                    </h4>
                  </v-col>
                  <v-col cols="auto">
                    {{ addonItem.serial }}
                  </v-col>
                </v-row>
              </div>
              <div v-else>Keine gebucht</div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card color="default" outlined class="elevation-0 mb-6">
            <v-card-title class="card-title-grey">
              Teilnehmer
            </v-card-title>
            <v-card-text>
              <div v-if="booking.participants.length === 0">
                Keine angegeben
              </div>
              <template v-else>
                <v-row
                  v-for="participant in booking.participants"
                  :key="participant.email"
                >
                  <v-col>
                    <h4 class="black--text">
                      <b>
                        {{ participant.first_name }}
                        {{ participant.last_name }}
                      </b>
                      <span v-if="participant.role === 'organizer'">
                        (Organisator)
                      </span>
                      <span v-else>
                        (Teilnehmer)
                      </span>
                    </h4>
                    {{ participant.email }}
                  </v-col>
                  <v-col cols="auto">
                    <participant-status-label :participant="participant" />
                  </v-col>
                </v-row>
              </template>
            </v-card-text>
          </v-card>
          <v-card color="default" outlined class="elevation-0">
            <v-card-title class="card-title-grey">
              Parking
            </v-card-title>
            <v-card-text>
              <div v-if="booking.parkings.length === 0">
                Keine Parktickets gebucht
              </div>
              <template v-else>
                <v-row v-for="parking in booking.parkings" :key="parking.id">
                  <v-col>
                    <h4 class="black--text">
                      <b>
                        {{
                          parking.car_number
                            ? parking.car_number
                            : parking.email
                        }}
                      </b>
                    </h4>
                    am {{ parking.valid_from | formatDate }} von
                    {{ parking.valid_from | formatTime }} Uhr bis
                    {{ parking.valid_until | formatTime }} Uhr
                  </v-col>
                  <v-col cols="auto">
                    <parking-status-label :parking="parking" />
                  </v-col>
                </v-row>
              </template>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card color="default" outlined class="elevation-0 mb-6">
            <v-card-title class="card-title-grey">
              Bezahlung
            </v-card-title>
            <v-card-text>
              <div v-if="booking.payment_transactions.length === 0">
                Keine Bezahlung vorhanden
              </div>
              <template v-else>
                <v-row
                  v-for="transactionPayment in booking.payment_transactions"
                  :key="transactionPayment.id"
                >
                  <v-col>
                    <h4 class="black--text">
                      <b>
                        #{{ transactionPayment.id }}
                        <span v-if="transactionPayment.remote_id"
                          >/ {{ transactionPayment.remote_id }}
                        </span>
                      </b>
                    </h4>
                    {{ formatPaymentAmount(transactionPayment) }} -
                    {{ formatPaymentType(transactionPayment) }}
                  </v-col>
                  <v-col cols="auto">
                    <v-btn
                      fab
                      x-small
                      elevation="0"
                      @click="showPaymentDetails(transactionPayment)"
                    >
                      <v-icon>live_help</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="auto">
                    <payment-status-label :payment="transactionPayment" />
                  </v-col>
                </v-row>
              </template>
            </v-card-text>
          </v-card>
          <v-card color="default" outlined class="elevation-0 mb-6">
            <v-card-title class="card-title-grey">
              Catering
            </v-card-title>
            <v-card-text>
              <div v-if="booking.caterings.length === 0">
                Keine Bestellungen vorhanden
              </div>
              <template v-else>
                <v-row v-for="catering in booking.caterings" :key="catering.id">
                  <v-col>
                    <h4 class="black--text">
                      <b>
                        {{ catering.refid }}
                      </b>
                    </h4>
                    {{ catering.created_at | formatDateTime }}
                  </v-col>
                  <v-col cols="auto">
                    <catering-status-label :catering="catering" />
                  </v-col>
                </v-row>
              </template>
            </v-card-text>
          </v-card>
          <v-card
            v-if="booking.event_management_info"
            color="default"
            outlined
            class="elevation-0"
          >
            <v-card-title class="card-title-grey">
              BP Event
            </v-card-title>
            <v-card-text>
              <pre>{{ booking.event_management_info }}</pre>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <info-dialog ref="infoDialog" />

    <entity-log-dialog
      v-if="booking"
      entity-name="bookings"
      :entity-id="booking.id"
      ref="entityLogDialog"
    />
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import BookingStatusLabel from "@/components/labels/BookingStatusLabel";
import PaymentStatusLabel from "@/components/labels/PaymentStatusLabel.vue";
import ParkingStatusLabel from "@/components/labels/ParkingStatusLabel.vue";
import ParticipantStatusLabel from "@/components/labels/ParticipantStatusLabel.vue";
import CateringStatusLabel from "@/components/labels/CateringStatusLabel.vue";
import TooltipButton from "@/components/forms/TooltipButton";
import InfoDialog from "@/components/dialogs/InfoDialog";
import EntityLogDialog from "@/components/dialogs/EntityLogDialog";

export default {
  components: {
    BookingStatusLabel,
    PaymentStatusLabel,
    ParkingStatusLabel,
    ParticipantStatusLabel,
    CateringStatusLabel,
    TooltipButton,
    InfoDialog,
    EntityLogDialog
  },

  data: () => ({
    booking: null
  }),

  computed: {
    ...mapState("app", ["configs"]),

    bookingId() {
      return this.$route.params.id;
    },
    bookingDate() {
      return moment(this.booking.starts_at).format("DD.MM.YYYY");
    },
    bookingStart() {
      return moment(this.booking.starts_at).format("HH:mm") + " Uhr";
    },
    bookingEnd() {
      return moment(this.booking.ends_at).format("HH:mm") + " Uhr";
    },
    seating() {
      return this.configs.seating_types[this.booking.seating.type];
    }
  },

  created() {
    this.fetchBooking();
  },

  methods: {
    /**
     * Fetch user from server.
     */
    fetchBooking() {
      this.$api.http
        .get(`backoffice/bookings/${this.bookingId}`)
        .then(response => {
          this.booking = response.data;
        });
    },

    /**
     * Export a function sheet.
     */
    exportReceipt() {
      window.open(this.booking.receipt_url, "_blank");
    },

    /**
     * Export a function sheet.
     */
    exportFunctionSheet() {
      window.open(this.booking.function_sheet_url, "_blank");
    },

    /**
     * Format payment amount.
     */
    formatPaymentAmount(paymentTransaction) {
      return "CHF " + (paymentTransaction.amount / 100).toFixed(2);
    },

    /**
     * Format payment type.
     */
    formatPaymentType(paymentTransaction) {
      return paymentTransaction.type === "online"
        ? "Online (Kreditkarte)"
        : "Monatsrechnung";
    },

    /**
     * Open info dialog.
     */
    showPaymentDetails(paymentTransaction) {
      this.$refs.infoDialog.entity = paymentTransaction;
      this.$refs.infoDialog.open = true;
    }
  }
};
</script>

<style lang="scss">
.users-edit {
  .card-title-grey {
    background-color: #eee;
    padding: 10px 15px;
    font-size: 16px;
    margin-bottom: 20px;
  }
}
</style>
