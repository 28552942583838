<template>
  <v-dialog v-model="open" scrollable width="500">
    <v-card>
      <v-card-title class="primary white--text">
        <span class="subtitle-1">Info</span>
      </v-card-title>

      <v-card-text class="mt-3">
        <pre>
          {{ entity }}
        </pre>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default" text @click="close()">
          Schliessen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    open: false,
    entity: null
  }),

  methods: {
    /**
     * Close.
     */
    close() {
      this.open = false;
    }
  }
};
</script>
