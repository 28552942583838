<template>
  <v-dialog v-model="open" scrollable width="800">
    <v-card>
      <v-card-title class="primary white--text">
        <span class="subtitle-1">History</span>
      </v-card-title>

      <v-card-text class="mt-3">
        <v-data-table
          :headers="headers"
          :items="logs"
          :items-per-page="15"
          class="elevation-0"
          disable-sort
          dense
        >
          <template v-slot:item.created_at="{ item }">
            {{ item.created_at | formatDateTime }}
          </template>

          <template v-slot:item.type="{ item }">
            <span v-if="item.type === 'created'">Erfasst</span>
            <span v-else-if="item.type === 'updated'">Bearbeitet</span>
            <span v-else-if="item.type === 'deleted'">Gelöscht</span>
            <span v-else>{{ item.type }}</span>
          </template>

          <template v-slot:item.user="{ item }">
            <span v-if="item.user">
              {{ item.user.first_name }} {{ item.user.last_name }}
            </span>
            <span v-else>System</span>
          </template>
        </v-data-table>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close()">
          Schliessen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: {
    entityId: {
      required: true,
      type: Number
    },
    entityName: {
      required: true,
      type: String
    }
  },
  data: () => ({
    open: false,
    logs: [],
    headers: [
      {
        text: "Zeitpunkt",
        value: "created_at"
      },
      { text: "Aktion", value: "type" },
      { text: "Von", value: "user" }
    ]
  }),

  watch: {
    open(value) {
      if (value) {
        this.fetchEntityLogs();
      }
    }
  },

  methods: {
    /**
     * Fetch logs from server
     */
    fetchEntityLogs() {
      this.$api.http
        .get(`backoffice/entity-logs/${this.entityName}/${this.entityId}`)
        .then(response => {
          this.logs = response.data;
        })
        .catch(error => {
          this.$toast.error(`${this.entityName} konnte nicht geladen werden.`);
        });
    },

    /**
     * Close dialog.
     */
    close() {
      this.open = false;
    }
  }
};
</script>

<style>
.entity-logs {
}
</style>
