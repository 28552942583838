<template>
  <div class="participant-status-label">
    <v-chip
      v-if="participant.status === 'confirmed'"
      color="success"
      label
      :small="small"
      :large="large"
    >
      Bestätigt
    </v-chip>
    <v-chip
      v-else-if="participant.status === 'canceled'"
      color="danger"
      label
      :small="small"
      :large="large"
    >
      Abgesagt
    </v-chip>
    <v-chip v-else color="warning" label :small="small" :large="large">
      Ausstehend
    </v-chip>
  </div>
</template>

<script>
export default {
  props: {
    participant: {
      type: Object,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style></style>
