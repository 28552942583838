<template>
  <div class="catering-status-label">
    <v-chip
      v-if="catering.status === 'confirmed'"
      color="success"
      label
      :small="small"
      :large="large"
    >
      Bestätigt
    </v-chip>
    <v-chip
      v-else-if="catering.status === 'cash'"
      color="info"
      label
      :small="small"
      :large="large"
    >
      Barzahlung
    </v-chip>
    <v-chip
      v-else-if="catering.status === 'canceled'"
      color="danger"
      label
      :small="small"
      :large="large"
    >
      Storniert
    </v-chip>
    <v-chip v-else color="warning" label :small="small" :large="large">
      In Bearbeitung
    </v-chip>
  </div>
</template>

<script>
export default {
  props: {
    catering: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
