<template>
  <div class="payment-status-label">
    <v-chip
      v-if="payment.status === 'confirmed'"
      color="success"
      label
      :small="small"
      :large="large"
    >
      Verbucht
    </v-chip>
    <v-chip
      v-else-if="payment.status === 'authorized'"
      color="success"
      label
      :small="small"
      :large="large"
    >
      Autorisiert
    </v-chip>
    <v-chip
      v-else-if="payment.status === 'canceled'"
      color="danger"
      label
      :small="small"
      :large="large"
    >
      Storniert
    </v-chip>
    <v-chip
      v-else-if="payment.status === 'account'"
      color="info"
      label
      :small="small"
      :large="large"
    >
      Auf Rechnung
    </v-chip>
    <v-chip v-else color="warning" label :small="small" :large="large">
      In Bearbeitung
    </v-chip>
  </div>
</template>

<script>
export default {
  props: {
    payment: {
      type: Object,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style></style>
